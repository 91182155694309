import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HskService } from '../hsk-service.service';
import { Router, Route } from '@angular/router';
import { OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
})
export class ArticlesComponent {

  public content: any[];
  Root = "";
  FolderPath = "";
  Title = "";

  constructor(private _hskService: HskService, private router: Router, private route: ActivatedRoute) {
    console.log("constructor calling..");
  }

  getTextContent(FolderPath) {
    this.Root = 'ClientApp\\dist\\assets\\wisdom-text\\';
    this.FolderPath = this.Root + this.FolderPath + ".txt";
    //console.log('this.FolderPath : ' + this.FolderPath);
    this._hskService.GetTextContent(this.FolderPath).subscribe(
      data => {
        this.content = data;
        console.log(data);
      },
      error => console.log('Error in getting data'),
      () => {
        console.log('success to update data...');
      });

  }

  ngOnInit() {
    // First get the product id from the current route.
    const routeParams = this.route.snapshot.paramMap;
    const productIdFromRoute = String(routeParams.get('Article'));
    console.log("ngOnInit method calling..");

    this.FolderPath = productIdFromRoute;
    this.Title = productIdFromRoute;
    this.getTextContent(this.FolderPath);
  }

}
