import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HskService } from '../hsk-service.service';
import { Router, Route } from '@angular/router';
import { OnInit } from '@angular/core';
import { QuestionClass } from '../questionClass';
import { ChoiceClass } from '../choiceClass';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-hsk-management',
  templateUrl: './hsk-management.component.html'
})

export class HskManagementComponent implements OnInit {

  public questions: any[];
  public choices: any[];
  groups = ['A', 'I', 'D'];
  difficulties = ['HSK01', 'HSK02', 'HSK03', 'HSK04', 'HSK05','HSK06'];
  mchoices = ['A', 'B', 'C','D'];
  now = new Date();
  
  choiceModel = new ChoiceClass("00000000-0000-0000-0000-000000000000", "00000000-0000-0000-0000-000000000000", "", "", "", true, 0, "A", this.now, null, "00000000-0000-0000-0000-000000000000", null);
  questionModel = new QuestionClass("00000000-0000-0000-0000-000000000000", "", "", "", "", this.now, null, "00000000-0000-0000-0000-000000000000", null, null);
 
  constructor(private _hskService: HskService, private router: Router) {
    console.log("constructor calling..");
  }
  getQuestion() {
    this._hskService.GetQuestion().subscribe(
      data => {
        this.questions = data;
        console.log(data);
      },
      error => console.log('Error in getting Question list'),
      () => {
        console.log('success to update Question list...');
      });

  }

  getChoice() {

    this._hskService.GetChoice().subscribe(
      data => {
        this.choices = data;
        console.log(data);
      },
      error => console.log('Error in getting Choice list'),
      () => {
        console.log('success to update Choice list...');
      });

  }

  setLeadingZero(str: string) {

    if (str.length < 3) {
      str = str.padStart(3, '0');
    }
    if (str.length > 3 ){
      str = str.slice(1);
    }

    this.questionModel.QuestionNo = str;

  }

  setInnerHtml(str: string) {

    this.questionModel.QuestionText = str.replace(/(?:\r\n|\r|\n)/g, '<br>');
    this.choiceModel.ChoiceText = str.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }


  public deleteQuestion(questionNo, index) {

    if (confirm("Are you sure to delete Question " + questionNo + " ?")) {

      this._hskService.DeleteQuestion(questionNo)
        .subscribe(
          () => {
            data => console.log('success', data);
            this.questions.splice(index, 1);
          },
          error => console.log('Error', error)
        );

    }
  }

  public deleteChoice(choiceNo, questionNo, index) {
    if (confirm("Are you sure to delete " + choiceNo + " of Question " + questionNo + " ?")) {
      this._hskService.DeleteChoice(choiceNo, questionNo)
        .subscribe(
          () => {
            data => console.log('success', data);
            this.choices.splice(index, 1);
          },
          error => console.log('Error', error)
        );
    }
    
  }

  SubmitDetails(questionData) {
    questionData.questionId = "00000000-0000-0000-0000-000000000000";
    questionData.createDateTime = new Date();
    questionData.createBy = "00000000-0000-0000-0000-000000000000";

    console.log(questionData);
    if (questionData.questionId == "00000000-0000-0000-0000-000000000000") {
      this._hskService.AddQuestion(questionData)
        .subscribe(
          (data) => {

            data => console.log('success', data);
            
            this.getQuestion();
            var currentElement = this.questions[this.questions.length];
            this.questions.splice(this.questions.length, 0, currentElement);
            this.questionModel.QuestionNo = '';
            this.questionModel.QuestionText = '';
            this.questionModel.Difficulty = '';
            this.questionModel.ActiveStatus = '';
          },
          error => console.log('Error', error)
        );
    } else {
      console.log("Edit question..");
    }

  }

  clear(input: HTMLInputElement) {
    input.value = ''; // null should work too
  }

  setQuestionChoice(v1: any) {
    for (var val of this.questions) {
      console.log("val.QuestionId " + val.questionId)
      if (val.questionId == v1) {
        this.choiceModel.QuestionNo = val.questionNo;
      }
    }
    console.log("the selected value is " + this.choiceModel.QuestionNo);

  }

  SubmitChoiceDetails(choiceData) {
    choiceData.choiceId = "00000000-0000-0000-0000-000000000000";
    choiceData.createDateTime = new Date();
    choiceData.createBy = "00000000-0000-0000-0000-000000000000";

    console.log(choiceData);
    if (choiceData.choiceId == "00000000-0000-0000-0000-000000000000") {
      this._hskService.AddChoice(choiceData)
        .subscribe(
          (data) => {
            data => console.log('success', data);
            this.getChoice();
            var currentElement = this.choices[this.choices.length];
            this.choices.splice(this.choices.length, 0, currentElement);
            this.choiceModel.ChoiceNo = '';
            this.choiceModel.ChoiceText = '';
            this.choiceModel.QuestionId = "00000000-0000-0000-0000-000000000000";
            this.choiceModel.IsCorrect = false;
            this.choiceModel.ActiveStatus = '';
          },
          error => console.log('Error', error)
        );
    } else {
      console.log("Edit choice..");
    }

  }


  ngOnInit() {
    console.log("ngOnInit method calling..");
    this.getQuestion();
    this.getChoice();
  }
}
