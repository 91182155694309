
import { Component, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QuestionClass } from './questionClass';
import { ChoiceClass } from './choiceClass';


@Injectable({
  providedIn: 'root'
})
export class HskService {

  _url = '';// may be different in your system, so change accorringly
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._url = baseUrl + 'api/';
    console.log('url : ' + this._url);
  }

  AddQuestion(question: QuestionClass) {
    return this._http.post<any>(this._url + 'Question/AddQuestion', question)
  }

  AddChoice(choice: ChoiceClass) {
    return this._http.post<any>(this._url + 'Choice/AddChoice', choice)
  }

  DeleteQuestion(QuestionNo: QuestionClass) {
    return this._http.delete<any>(this._url + 'Question/DeleteQuestion?QuestionNo=' + QuestionNo);
  }
  DeleteChoice(ChoiceNo: ChoiceClass, QuestionNo: QuestionClass) {
    return this._http.delete<any>(this._url + 'Choice/DeleteChoice?choiceNo=' + ChoiceNo +'&questionNo=' + QuestionNo);
  }

  EditChoiceScore(params) {
    return this._http.put<any[]>(this._url + 'Choice/EditChoiceScore?' + params, params);
  }

  GetQuestion(): Observable<any[]> {
    return this._http.get<any[]>(this._url + 'Question/GetQuestion');
  }
  GetChoice(): Observable<any[]> {
    return this._http.get<any[]>(this._url + 'Choice/GetChoice');
  }

  GetQuestionId(Difficulty: string): Observable<any[]> {
    return this._http.get<any[]>(this._url + 'Question/GetQuestionId?Difficulty=' + Difficulty);
  }
  GetChoiceId(Difficulty: string): Observable<any[]> {
    return this._http.get<any[]>(this._url + 'Choice/GetChoiceId?Difficulty=' + Difficulty);
  }
  GetQuestionDifficultyList(): Observable<any[]> {
    return this._http.get<any[]>(this._url + 'Question/GetQuestionDifficultyList');
  }

  //FileService
  GetFileName(folderPath: string): Observable<any[]> {
    return this._http.get<any[]>(this._url + 'File/GetFileName?folderPath=' + folderPath);
  }

  GetSubDirectories(folderPath: string): Observable<any[]> {
    return this._http.get<any[]>(this._url + 'File/GetSubDirectories?folderPath=' + folderPath);
  }

  GetTextContent(textFileName: string): Observable<any[]> {
    return this._http.get<any[]>(this._url + 'File/GetTextContent?textFileName=' + textFileName);
  }

}
