import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { BlogsComponent } from './blogs/blogs.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { ArticlesComponent } from './articles/articles.component';
import { MobileAppsComponent } from './mobile-apps/mobile-apps.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { HskQuestionComponent } from './hsk-question/hsk-question.component';

import { HskManagementComponent } from './hsk-management/hsk-management.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    AboutComponent,
    CounterComponent,
    FetchDataComponent,
    HskQuestionComponent,
    HskManagementComponent,
    MobileAppsComponent,
    BlogsComponent,
    ImageGalleryComponent,
    ArticlesComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'about', component: AboutComponent },
      //{ path: 'counter', component: CounterComponent },
      //{ path: 'fetch-data', component: FetchDataComponent },
      { path: 'hsk-question/:Difficulty', component: HskQuestionComponent },
      { path: 'blogs', component: BlogsComponent },
      { path: 'image-gallery/:Gallery', component: ImageGalleryComponent },
      { path: 'articles/:Article', component: ArticlesComponent },
      { path: 'mobile-apps', component: MobileAppsComponent },
      { path: 'hsk-management', component: HskManagementComponent },
    ])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
