export class ChoiceClass {
  constructor(
    public ChoiceId: string,
    public QuestionId: string,
    public ChoiceNo: string,
    public QuestionNo: string,
    public ChoiceText: string,
    public IsCorrect: boolean,
    public StatisticCount: number,
    public ActiveStatus: string,
    public CreateDateTime: Date,
    public UpdateDateTime: Date,
    public CreateBy: string,
    public UpdateBy: string
  ) { }
}
