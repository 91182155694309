import { ChoiceClass } from "./choiceClass";

export class QuestionClass {
  constructor(
    public QuestionId: string,
    public QuestionNo: string,
    public QuestionText: string,
    public Difficulty: string,
    public ActiveStatus: string,
    public CreateDateTime: Date,
    public UpdateDateTime: Date,
    public CreateBy: string,
    public UpdateBy: string,
    public Choice: ChoiceClass[]
  ) { }
}
