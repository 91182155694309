import { Component } from '@angular/core';
import { HskService } from '../hsk-service.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
})
export class BlogsComponent {

  public galleries: any[];
  public articles: any[];
  Root = "";
  FolderPath = "";
  Title = "";

  constructor(private _hskService: HskService) {
    console.log("constructor calling..");
  }

  getSubDirectoriesGallery() {
    this.Root = 'ClientApp\\dist\\assets\\gallery\\';
    this.FolderPath = this.Root;

    this._hskService.GetSubDirectories(this.FolderPath).subscribe(
      data => {
        this.galleries = data;
        console.log(data);
      },
      error => console.log('Error in getting list'),
      () => {
        console.log('success getting list...');
      });

  }

  getFileName() {
    this.Root = 'ClientApp\\dist\\assets\\wisdom-text\\';
    this.FolderPath = this.Root;
    //console.log('this.FolderPath : ' + this.FolderPath);
    this._hskService.GetFileName(this.FolderPath).subscribe(
      data => {
        this.articles = data;
        console.log(data);
      },
      error => console.log('Error in getting text list'),
      () => {
        console.log('success getting list...');
      });

  }

  ngOnInit() {

    this.getSubDirectoriesGallery();
    this.getFileName();
  }

}
