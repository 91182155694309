import { Component } from '@angular/core';
import { HskService } from '../hsk-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

  public levels: any[];

  constructor(private _hskService: HskService) {
    console.log("constructor calling..");
  }

  GetLevel() {
    this._hskService.GetQuestionDifficultyList().subscribe(
      data => {
        this.levels = data;
        console.log(data);
      },
      error => console.log('Error in getting Lefel list'),
      () => {
        console.log('success to get Level list...');
      });

  }

  ngOnInit() {

    console.log("ngOnInit method calling..");
    this.GetLevel();

  }

}
