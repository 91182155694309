import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HskService } from '../hsk-service.service';
import { Router, Route } from '@angular/router';
import { OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
})
export class ImageGalleryComponent {

  public paths: any[];
  Root = "";
  FolderPath = "";
  Title = "";

  constructor(private _hskService: HskService, private router: Router, private route: ActivatedRoute) {
    console.log("constructor calling..");
  }

  GetFileName(FolderPath) {
    this.Root = 'ClientApp\\dist\\assets\\gallery\\';
    this.FolderPath = this.Root + this.FolderPath;
    //console.log('this.FolderPath : ' + this.FolderPath);
    this._hskService.GetFileName(this.FolderPath).subscribe(
      data => {
        this.paths = data;
        console.log(data);
      },
      error => console.log('Error in getting Question list'),
      () => {
        console.log('success to update Question list...');
      });

  }

  ngOnInit() {
    // First get the product id from the current route.
    const routeParams = this.route.snapshot.paramMap;
    const productIdFromRoute = String(routeParams.get('Gallery'));
    console.log("ngOnInit method calling..");

    this.FolderPath = productIdFromRoute;
    this.Title = productIdFromRoute;
    this.GetFileName(this.FolderPath);
  }

}
