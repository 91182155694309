import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HskService } from '../hsk-service.service';
import { Router, Route } from '@angular/router';
import { OnInit } from '@angular/core';
import { QuestionClass } from '../questionClass';
import { ChoiceClass } from '../choiceClass';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-hsk-question',
  templateUrl: './hsk-question.component.html'
})

export class HskQuestionComponent {

  public questions: any[];
  public choices: any[];
  choiceIdArr = new Array();
  choiceNoArr = new Array();
  questionNoArr = new Array();
  isCorrectArr = new Array();
  now = new Date();
  IsSubmit = false;
  AnsCountValid = false;
  Difficulty = "";
  Score = "";
  color = "red";
  percent: number;
  params = "";

  constructor(private _hskService: HskService, private router: Router, private route: ActivatedRoute) {
    console.log("constructor calling..");

}

  getQuestionId(Difficulty) {
    this._hskService.GetQuestionId(this.Difficulty).subscribe(
      data => {
        this.questions = data;
        console.log(data);
      },
      error => console.log('Error in getting Question list'),
      () => {
        console.log('success to update Question list...');
      });

  }

  getChoiceId(Difficulty) {

    this._hskService.GetChoiceId(this.Difficulty).subscribe(
      data => {
        this.choices = data;
        console.log(data);
      },
      error => console.log('Error in getting Choice list'),
      () => {
        console.log('success to update Choice list...');
      });

  }

  getData(choiceNo, questionNo, isCorrect, choiceId) {
      if (questionNo == this.questionNoArr[this.questionNoArr.length - 1]) {
        this.choiceNoArr.splice(-1);
        this.isCorrectArr.splice(-1);
        this.choiceIdArr.splice(-1);
        this.choiceNoArr.push(choiceNo);
        this.isCorrectArr.push(isCorrect);
        this.choiceIdArr.push(choiceId);
      } else {
        this.choiceNoArr.push(choiceNo);
        this.questionNoArr.push(questionNo);
        this.isCorrectArr.push(isCorrect);
        this.choiceIdArr.push(choiceId);
      }
    console.log("get array " + choiceNo + " / " + questionNo + " / " + choiceId + " len : " + this.choiceNoArr.length);
    if (this.choiceNoArr.length == this.questions.length) {
      this.AnsCountValid = true;
    }
    
  }

  SubmitAwnsers(choiceData) {

    if (confirm("Are you sure to validate the awnsers ?")) {
      this.IsSubmit = true;
      length = this.isCorrectArr.filter(d => d == true).length;
      this.percent = (length / this.questions.length) * 100;
      this.Score = length.toString() + "/" + this.questions.length.toString();

      if (this.percent > 50) {
        this.color = "limegreen";
      } else {
        this.color = "red";
      }

      this.choiceIdArr.forEach((currentValue, index) => {
        this.params +=
          "choiceId=" +
        currentValue +
          "&";
      });

      console.log("param " + this.params);

      this._hskService.EditChoiceScore(this.params.slice(0, -1))
        .subscribe(
          () => {
            data => console.log('success', data);
          },
          error => console.log('Error', error)
        );
    }
    this.AnsCountValid = false;

  }

  ngOnInit() {
    // First get the product id from the current route.
    const routeParams = this.route.snapshot.paramMap;
    const productIdFromRoute = String(routeParams.get('Difficulty'));
    console.log("ngOnInit method calling..");

    this.Difficulty = productIdFromRoute;

    this.getQuestionId(this.Difficulty);
    this.getChoiceId(this.Difficulty);
  }

}
